import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import SidebarLayout from './layouts/SidebarLayout';
import FullscreenLayout from './layouts/FullscreenLayout';
import LoadingScreen from './components/LoadingScreen';
import AuthGuard from './components/Guards/AuthGuard';
import GuestGuard from './components/Guards/GuestGuard';
import {
    PeopleRounded,
    DashboardRounded,
    PeopleOutlined,
    Construction,
    CloudUpload as Cloud,
    TextSnippetOutlined,
    TipsAndUpdatesRounded,
} from '@mui/icons-material';
import { PERMISSIONS, SCOPES, SCOPES_SW } from './permissionMaps';
import PermissionsGateContext from './contexts/PermissionsGateContext';
import NotFoundPage from './components/Pages/NotFoundPage';

export const renderRoutes = (routes = []) => (
    <Suspense fallback={<LoadingScreen />}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;
                const Scopes = route.scopes || [];
                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Guard>
                                <Layout>
                                    {route.routes ? (
                                        <PermissionsGateContext scopes={Scopes}>
                                            {renderRoutes(route.routes)}
                                        </PermissionsGateContext>
                                    ) : (
                                        <PermissionsGateContext scopes={Scopes}>
                                            <Component {...props} />
                                        </PermissionsGateContext>
                                    )}
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
            <Route
                key={-1}
                render={(props) => (
                    <AuthGuard>
                        <SidebarLayout>{<NotFoundPage />}</SidebarLayout>
                    </AuthGuard>
                )}
            />
        </Switch>
    </Suspense>
);

const routes = [
    {
        exact: true,
        guard: GuestGuard,
        path: '/',
        component: lazy(() => import('../src/views/LoginView')),
    },
    {
        exact: true,
        layout: SidebarLayout,
        guard: AuthGuard,
        path: '/home',
        component: lazy(() => import('../src/views/UserDashboard')),
        //scopes: [SCOPES.restrictedCanView],
    },
    {
        exact: true,
        layout: SidebarLayout,
        guard: AuthGuard,
        path: '/home/',
        component: lazy(() => import('../src/views/UserDashboard')),
        //scopes: [SCOPES.restrictedCanView],
    },
    {
        exact: true,
        layout: SidebarLayout,
        guard: AuthGuard,
        path: '/profile',
        component: lazy(() => import('./views/ProfilePage')),
    },
    {
        exact: true,
        layout: SidebarLayout,
        guard: AuthGuard,
        path: '/apps',
        component: lazy(() => import('./views/apps')),
    },
    {
        path: '/videomanager',
        section: 'VideoManager',
        guard: AuthGuard,
        layout: SidebarLayout,
        scopes: PERMISSIONS[SCOPES.manageVideoManager],
        routes: [
            {
                exact: true,
                path: '/videomanager/overview',
                scopes: PERMISSIONS[SCOPES.manageVideoManager],
                appName: 'Video Manager EX',
                logo: Construction,
                component: lazy(() =>
                    import('./views/apps/videomanager/ViewVideoManager')
                ),
            },
            {
                exact: true,
                path: '/videomanager/upload',
                scopes: PERMISSIONS[
                    (SCOPES.manageVideoManager,
                    SCOPES.uploadPackageVideoManager,
                    SCOPES.upgradeInternalVideoManager,
                    SCOPES.upgradeVideoManager)
                ],
                component: lazy(() =>
                    import('./views/apps/videomanager/UploadFiles')
                ),
            },
        ],
    },
    {
        path: '/activities',
        guard: AuthGuard,
        layout: SidebarLayout,
        scopes: PERMISSIONS[SCOPES.viewActivity],
        routes: [
            {
                exact: true,
                path: '/activities/view',
                scopes: PERMISSIONS[SCOPES.viewActivity],
                component: lazy(() =>
                    import('./views/apps/activities/ViewActivities')
                ),
            },
        ],
    },
    {
        path: '/order',
        guard: AuthGuard,
        layout: SidebarLayout,
        scopes: PERMISSIONS[SCOPES_SW.viewRMAReport],
        routes: [
            {
                exact: true,
                path: '/order/new',
                scopes: PERMISSIONS[SCOPES_SW.viewRMAReport],
                component: lazy(() =>
                    import('./views/Supportweb/Order/New/index')
                ),
            },
            {
                exact: true,
                path: '/order/report',
                scopes: PERMISSIONS[SCOPES_SW.viewRMAReport],
                component: lazy(() =>
                    import('./views/Supportweb/Order/Report')
                ),
            },
            {
                exact: true,
                path: '/order/:orderId',
                scopes: [SCOPES.createRMA],
                component: lazy(() => import('./views/Supportweb/Order/Edit')),
            },
            {
                exact: true,
                path: '/order/invoice/:orderId',
                scopes: [SCOPES.createRMA],
                component: lazy(() =>
                    import('./views/Supportweb/Order/invoice')
                ),
            },
        ],
    },
    {
        path: '/orderline',
        guard: AuthGuard,
        layout: SidebarLayout,
        scopes: PERMISSIONS[SCOPES_SW.viewRMAReport],
        routes: [
            {
                exact: true,
                path: '/orderline/edit/:lineId',
                scopes: [SCOPES.createRMA],
                component: lazy(() =>
                    import('./views/Supportweb/Order/OrderLine/edit')
                ),
            },
            {
                exact: true,
                path: '/orderline/new/:orderId',
                scopes: [SCOPES.createRMA],
                component: lazy(() =>
                    import('./views/Supportweb/Order/OrderLine/new')
                ),
            },
        ],
    },
    {
        path: '/rma',
        guard: AuthGuard,
        layout: SidebarLayout,
        scopes: PERMISSIONS[SCOPES_SW.viewRMAReport],
        routes: [
            {
                exact: true,
                path: '/rma/report',
                scopes: PERMISSIONS[SCOPES_SW.viewRMAReport],
                component: lazy(() => import('./views/Supportweb/RMA/Report')),
            },
            {
                exact: true,
                path: '/rma',
                scopes: PERMISSIONS[SCOPES_SW.createRMA],
                component: lazy(() => import('./views/Supportweb/RMA/Create')),
            },
            {
                exact: true,
                path: '/rma/:rmaId',
                scopes: [SCOPES.createRMA],
                component: lazy(() => import('./views/Supportweb/RMA/Edit')),
            },
            {
                exact: true,
                path: '/rma/line/receive',
                scopes: [SCOPES.createRMA],
                component: lazy(() =>
                    import('./views/Supportweb/RMALine/receiveEdit')
                ),
            },
            {
                exact: true,
                path: '/rma/line/new/:rmaId',
                scopes: [SCOPES.createRMA],
                component: lazy(() => import('./views/Supportweb/RMALine/new')),
            },
            {
                exact: true,
                path: '/rma/line/replaceSerial',
                scopes: [SCOPES.createRMA],
                component: lazy(() =>
                    import('./views/Supportweb/RMALine/replace')
                ),
            },
            {
                exact: true,
                path: '/rma/line/replaceFault',
                scopes: [SCOPES.createRMA],
                component: lazy(() =>
                    import('./views/Supportweb/RMALine/fault')
                ),
            },
            {
                exact: true,
                path: '/rma/requestPayment/:rmaId',
                scopes: [SCOPES.createRMA],
                component: lazy(() =>
                    import('./views/Supportweb/RMA/Edit/requestPayment')
                ),
            },
            {
                exact: true,
                path: '/rma/paymentReceived/:rmaId',
                scopes: [SCOPES.createRMA],
                component: lazy(() =>
                    import('./views/Supportweb/RMA/Edit/paymentReceived')
                ),
            },
        ],
    },
    {
        path: '/report',
        guard: AuthGuard,
        layout: SidebarLayout,
        scopes: PERMISSIONS[SCOPES_SW.viewRMAReport],
        routes: [
            {
                exact: true,
                path: '/report',
                scopes: PERMISSIONS[SCOPES_SW.viewRMAReport],
                component: lazy(() =>
                    import('./views/Supportweb/Reports/report')
                ),
            },
            {
                exact: true,
                path: '/report/sales',
                scopes: PERMISSIONS[SCOPES_SW.viewRMAReport],
                component: lazy(() =>
                    import('./views/Supportweb/Reports/sales')
                ),
            },
            {
                exact: true,
                path: '/report/shipment',
                scopes: PERMISSIONS[SCOPES_SW.viewRMAReport],
                component: lazy(() =>
                    import('./views/Supportweb/Reports/shipment')
                ),
            },
            {
                exact: true,
                path: '/report/warranty/:rmaId',
                scopes: PERMISSIONS[SCOPES_SW.viewRMAReport],
                component: lazy(() =>
                    import('./views/Supportweb/Reports/warrantyReports')
                ),
            },
            {
                exact: true,
                path: '/report/delivery',
                scopes: PERMISSIONS[SCOPES_SW.viewRMAReport],
                component: lazy(() =>
                    import('./views/Supportweb/Reports/delivery')
                ),
            },
        ],
    },
    {
        path: '/search',
        guard: AuthGuard,
        layout: SidebarLayout,
        scopes: PERMISSIONS[SCOPES_SW.viewRMAReport],
        routes: [
            {
                exact: true,
                path: '/search',
                scopes: PERMISSIONS[SCOPES_SW.viewRMAReport],
                component: lazy(() => import('./views/Supportweb/Search')),
            },
            {
                exact: true,
                path: '/search/result',
                scopes: PERMISSIONS[SCOPES_SW.viewRMAReport],
                component: lazy(() =>
                    import('./views/Supportweb/Search/result')
                ),
            },
        ],
    },
    {
        path: '/warranty',
        guard: AuthGuard,
        layout: SidebarLayout,
        scopes: PERMISSIONS[SCOPES_SW.viewRMAReport],
        routes: [
            {
                exact: true,
                path: '/warranty',
                scopes: PERMISSIONS[SCOPES_SW.viewRMAReport],
                component: lazy(() => import('./views/Supportweb/Warranty')),
            },
            {
                exact: true,
                path: '/warranty/new/:orderId',
                scopes: PERMISSIONS[SCOPES_SW.viewRMAReport],
                component: lazy(() =>
                    import('./views/Supportweb/WarrantySalesOrder/new')
                ),
            },
            {
                exact: true,
                path: '/warranty/view/:orderId',
                scopes: PERMISSIONS[SCOPES_SW.viewRMAReport],
                component: lazy(() =>
                    import('./views/Supportweb/WarrantySalesOrder/view')
                ),
            },
        ],
    },
    {
        path: '/delivery',
        guard: AuthGuard,
        layout: SidebarLayout,
        scopes: PERMISSIONS[SCOPES_SW.viewRMAReport],
        routes: [
            {
                exact: true,
                path: '/delivery/view/:deliveryId',
                scopes: [SCOPES.updateUsers, SCOPES.managerRMA],
                component: lazy(() =>
                    import('./views/Supportweb/Delivery/view')
                ),
            },
            {
                exact: true,
                path: '/delivery/create/:id',
                scopes: [SCOPES.updateUsers, SCOPES.managerRMA],
                component: lazy(() =>
                    import('./views/Supportweb/Delivery/create')
                ),
            },
        ],
    },
    {
        path: '/history',
        guard: AuthGuard,
        layout: SidebarLayout,
        scopes: PERMISSIONS[SCOPES_SW.viewRMAReport],
        routes: [
            {
                exact: true,
                path: '/history/:itemId',
                scopes: [SCOPES.updateUsers, SCOPES.managerRMA],
                component: lazy(() => import('./views/Supportweb/RMA/Edit')),
            },
        ],
    },
    {
        path: '/licence',
        section: 'Licence',
        guard: AuthGuard,
        layout: SidebarLayout,
        scopes: PERMISSIONS[SCOPES.manageLicense],
        routes: [
            {
                exact: true,
                path: '/licence/list',
                scopes: [
                    SCOPES.viewLicense,
                    SCOPES.manageLicense,
                    SCOPES.createLicense,
                ],
                appName: 'Licences',
                logo: TextSnippetOutlined,
                component: lazy(() =>
                    import(
                        './views/apps/licence/LicenceService/ViewLicencesList'
                    )
                ),
            },
            {
                exact: true,
                path: '/licence/create',
                scopes: [SCOPES.createLicense, SCOPES.manageLicense],
                component: lazy(() =>
                    import('./views/apps/licence/LicenceService/AddLicence')
                ),
            },
            {
                exact: true,
                path: '/licence/productcodes',
                scopes: [SCOPES.manageSpreadsheet],
                component: lazy(() =>
                    import('./views/apps/licence/LicenceService/ProductCodes')
                ),
            },
            {
                exact: true,
                path: '/licence/metrics',
                scopes: [SCOPES.manageSpreadsheet],
                component: lazy(() => import('./views/apps/licence/Metrics')),
            },
        ],
    },
    {
        path: '/gpt',
        section: 'ChatGPT',
        guard: AuthGuard,
        layout: SidebarLayout,
        scopes: PERMISSIONS[
            (SCOPES.adminAll,
            SCOPES.manageGenAi,
            SCOPES.queryGenericModelsAi,
            SCOPES.queryVMEXModelAi)
        ],
        routes: [
            {
                exact: true,
                path: '/gpt',
                scopes: [
                    SCOPES.adminAll,
                    SCOPES.manageGenAi,
                    SCOPES.queryGenericModelsAi,
                    SCOPES.queryVMEXModelAi,
                ],
                appName: 'VMEX AI',
                logo: TipsAndUpdatesRounded,
                component: lazy(() => import('./views/apps/gpt')),
            },
            {
                exact: true,
                path: '/gpt/queriesList',
                scopes: [SCOPES.adminAll, SCOPES.manageGenAi],
                component: lazy(() =>
                    import('./views/apps/gpt/ViewRequestsList')
                ),
            },
        ],
    },

    {
        path: '/rmaPortal',
        guard: AuthGuard,
        layout: SidebarLayout,
        scopes: PERMISSIONS[SCOPES.adminAll],
        routes: [
            {
                exact: true,
                path: '/rmaPortal/userList',
                scopes: [SCOPES.adminAll, SCOPES.managerRMA],
                component: lazy(() =>
                    import('./views/apps/userRMAPortal/ViewUserList')
                ),
            },
            {
                exact: true,
                path: '/rmaPortal/editUser/:userId',
                scopes: [SCOPES.adminAll, SCOPES.managerRMA],
                component: lazy(() =>
                    import('./views/apps/userRMAPortal/EditRMAUser')
                ),
            },
        ],
    },
    {
        path: '/customers',
        section: 'Customers',
        guard: AuthGuard,
        layout: SidebarLayout,
        scopes: PERMISSIONS[SCOPES.manageCustomer],
        routes: [
            {
                exact: true,
                path: '/customers',
                scopes: PERMISSIONS[SCOPES.manageCustomer],
                component: lazy(() =>
                    import('./views/apps/customers/CustomerService')
                ),
            },
            {
                exact: true,
                path: '/customers/customersList/view',
                scopes: PERMISSIONS[SCOPES.manageCustomer],
                appName: 'Customers',
                logo: PeopleOutlined,
                component: lazy(() =>
                    import(
                        './views/apps/customers/CustomerService/ViewCustomersList'
                    )
                ),
            },
            {
                exact: true,
                path: '/customers/addcustomer',
                scopes: [SCOPES.createCustomer, SCOPES.manageCustomer],
                component: lazy(() =>
                    import('./views/apps/customers/CustomerService/AddCustomer')
                ),
            },
            {
                exact: true,
                path: '/customers/customerService/edit/:customerId',
                component: lazy(() =>
                    import(
                        './views/apps/customers/CustomerService/EditCustomer'
                    )
                ),
                scopes: [
                    SCOPES.updateCustomer,
                    SCOPES.manageCustomer,
                    SCOPES.viewCustomer,
                ],
            },
            // {
            //     exact: true,
            //     path: '/customer/customerService/edit/:serviceId/:section',
            //     component: lazy(() =>
            //         import(
            //             './views/apps/customers/CustomerService/EditCustomer/EditService/EditServiceView'
            //         )
            //     ),
            //     scopes: [SCOPES.cloudopsCanEdit],
            // },
        ],
    },
    {
        path: '/cloudenvironment',
        section: 'Cloud Enviroment',
        guard: AuthGuard,
        layout: SidebarLayout,
        scopes: PERMISSIONS[SCOPES.manageEnvironment],
        routes: [
            {
                exact: true,
                path: '/cloudenvironment/add',
                scopes: [SCOPES.manageEnvironment, SCOPES.createEnvironment],
                appName: 'Cloud Environment',
                logo: Cloud,
                component: lazy(() =>
                    import('./views/apps/cloudenvironment/AddEnvironment')
                ),
            },
        ],
    },
    {
        path: '/dashboards',
        section: 'Dashboards',
        //scopes: [SCOPES.dashboardCanView],
        guard: AuthGuard,
        layout: FullscreenLayout,
        routes: [
            {
                exact: true,
                path: '/dashboards/serviceHealth',
                appName: 'Service Health',
                logo: DashboardRounded,
                //component: ServiceHealthDashboard,
                component: lazy(() =>
                    import(
                        './views/apps/monitoring/dashboards/ServiceHealthDashboard/index'
                    )
                ),
            },
            // {
            //   exact: true,
            //   path: "/dashboards/incidentDetail",
            //   appName: "Incidents In Detail",
            //   logo: DashboardRounded,
            //   component: IncidentInDetail
            // },
        ],
    },
    {
        path: '/admin',
        section: 'Admin',
        scopes: [SCOPES.adminAll, ...PERMISSIONS[SCOPES.manageUsers]],
        guard: AuthGuard,
        layout: SidebarLayout,
        routes: [
            {
                exact: true,
                path: '/admin/usersAndRoles',
                logo: PeopleRounded,
                scopes: PERMISSIONS[SCOPES.manageUsers],
                component: lazy(() =>
                    import('./views/apps/admin/UsersAndRoles/index')
                ),
            },
            {
                exact: true,
                path: '/admin/usersAndRoles/add',
                logo: PeopleRounded,
                scopes: [SCOPES.manageUsers],
                component: lazy(() =>
                    import('./views/apps/admin/UsersAndRoles/AddUsers/index')
                ),
            },
            {
                exact: true,
                path: '/admin/usersAndRoles/view',
                appName: 'Users and Roles',
                scopes: PERMISSIONS[SCOPES.manageUsers],
                logo: PeopleRounded,
                component: lazy(() =>
                    import('./views/apps/admin/UsersAndRoles/ViewUsers/index')
                ),
            },
            {
                exact: true,
                path: '/admin/usersAndRoles/edit/:userId',
                scopes: [SCOPES.updateUsers, SCOPES.manageUsers],
                component: lazy(() =>
                    import(
                        './views/apps/admin/UsersAndRoles/EditUserRoles/index'
                    )
                ),
            },
        ],
    },
    {
        path: '/metrics',
        guard: AuthGuard,
        layout: SidebarLayout,
        scopes: PERMISSIONS[SCOPES.adminAll],
        routes: [
            {
                exact: true,
                path: '/metrics',
                scopes: PERMISSIONS[SCOPES.admin],
                component: lazy(() => import('./views/MetricsDashboard/index')),
            },
        ],
    },
];

export default routes;
