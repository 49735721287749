import React, { createContext, useEffect, useReducer } from 'react';
import useAuth from '../hooks/useAuth';
import { ENDPOINT } from '../constants';
import _ from 'lodash';
import { useState } from 'react';

const initialCustomerState = {
    customers: null,
    selectors: null,
    versions: null,
    customerPerVersion: null,
    countryDataOrigin: null,
    deploymentType: null,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'CUSTOMER': {
            return {
                ...state,
                customer: action.payload,
            };
        }
        case 'CUSTOMERS': {
            return {
                ...state,
                customers: action.payload.customers,
                selectors: action.payload.selectors,
                versions: action.payload.versions,
                customerPerVersion: action.payload.customerPerVersion,
                countryDataOrigin: action.payload.countryDataOrigin,
                deploymentType: action.payload.deploymentType,
            };
        }
        default: {
            return { ...state };
        }
    }
};

const CustomerContext = createContext({
    ...initialCustomerState,
    method: 'Customer',
    getAllCustomers: () => {},
    renewCustomers: () => {},
});

export const CustomerProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialCustomerState);
    const { user } = useAuth();

    const endpoint = ENDPOINT + '/Customers/';

    const getAllCustomers = async () => {
        let data = [];
        if (!user.isAuthenticated) {
            return null;
        }
        try {
            const response = await fetch(endpoint, {
                method: 'get',
                headers: {
                    Authorization: `Bearer ${user.user.token}`,
                    'Content-Type': 'application/json',
                },
            });
            data = await response.json();
        } catch (err) {
            console.error(err);
        }
        return data;
    };

    const loadCustomerData = (data) => {
        var selectors = {
            Fqdn: [],
            InstanceType: [],
            CountryDataOrigin: [],
            HostingAccount: [],
            VideoManagerVersion: [],
            InstanceState: [],
            HostingLocation: [],
        };
        var versions = [];
        var customerPerVersion = [];
        var customerPerVersionNew = {};
        var countryDataOrigin = {};
        var deploymentType = {};
        data.forEach((customer) => {
            selectors.Fqdn.push(customer.Fqdn);
            selectors.InstanceType.push(customer.InstanceType);
            selectors.CountryDataOrigin.push(customer.CountryDataOrigin);
            selectors.HostingAccount.push(customer.HostingAccount);
            selectors.VideoManagerVersion.push(customer.VideoManagerVersion);
            selectors.InstanceState.push(customer.InstanceState);
            selectors.HostingLocation.push(customer.HostingLocation);
            versions.push(customer.VideoManagerVersion);
            const region =
                customer.CountryDataOrigin === '??'
                    ? 'Unkown'
                    : customer.CountryDataOrigin;
            if (!countryDataOrigin[region]) {
                countryDataOrigin[region] = [];
            }
            if (!customerPerVersionNew[customer.VideoManagerVersion]) {
                customerPerVersionNew[customer.VideoManagerVersion] = [];
            }
            if (!deploymentType[customer.DeploymentType]) {
                deploymentType[customer.DeploymentType] = 0;
            }
            if (
                _.toUpper(customer.DeploymentType) === 'LIVE' ||
                _.toUpper(customer.DeploymentType) === 'TRIAL'
            ) {
                countryDataOrigin[region].push(customer);
                customerPerVersionNew[customer.VideoManagerVersion].push(
                    customer
                );
                deploymentType[customer.DeploymentType]++;
            }
        });
        versions = _.compact(_.uniq(versions, true));
        versions.forEach((version, k) => {
            versions[k] = {
                v: version,
                i: parseInt(version.split('.').join('')),
            };
        });
        versions = _.sortBy(versions, ['i'], ['asc']);
        _.keys(selectors).forEach((item) => {
            selectors[item] = _.compact(_.uniq(selectors[item], true));
        });
        versions.forEach((version) => {
            customerPerVersion[version.i] = {
                customers: [],
                count: 0,
                version: version.v,
                i: parseInt(version.i),
            };
        });
        data.forEach((customer) => {
            let v = customer.VideoManagerVersion.split('.').join('');
            if (customerPerVersion[v]) {
                customerPerVersion[v].customers.push(customer);
            }
        });
        customerPerVersion.forEach((v, k) => {
            customerPerVersion[k].count = v.customers.length;
        });
        return {
            selectors,
            versions,
            customerPerVersion: customerPerVersionNew,
            countryDataOrigin,
            deploymentType,
        };
    };

    const initData = async () => {
        try {
            const customers = await getAllCustomers();
            if (customers) {
                const {
                    selectors,
                    versions,
                    customerPerVersion,
                    countryDataOrigin,
                    deploymentType,
                } = loadCustomerData(customers);
                dispatch({
                    type: 'CUSTOMERS',
                    payload: {
                        customers,
                        selectors,
                        versions,
                        customerPerVersion,
                        countryDataOrigin,
                        deploymentType,
                    },
                });
            }
        } catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {
        initData();
    }, []);
    const renewCustomers = () => {
        initData();
    };

    return (
        <CustomerContext.Provider
            value={{
                ...state,
                getAllCustomers,
                renewCustomers,
            }}
        >
            {children}
        </CustomerContext.Provider>
    );
};

export default CustomerContext;
