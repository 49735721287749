/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addDailyActivity = /* GraphQL */ `
  query AddDailyActivity(
    $route: String
    $payload: String
    $message: String
    $type: String
  ) {
    addDailyActivity(
      route: $route
      payload: $payload
      message: $message
      type: $type
    )
  }
`;
export const updateDailyActivities = /* GraphQL */ `
  query UpdateDailyActivities($type: String) {
    updateDailyActivities(type: $type)
  }
`;
export const getQueriesGPT = /* GraphQL */ `
  query GetQueriesGPT($filter: DateFilterInput) {
    getQueriesGPT(filter: $filter)
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity(
    $filter: DateFilterInput
    $type: String
    $payloadId: String
  ) {
    getActivity(filter: $filter, type: $type, payloadId: $payloadId)
  }
`;
export const listS3 = /* GraphQL */ `
  query ListS3($key: String, $bucket: String) {
    listS3(key: $key, bucket: $bucket)
  }
`;
export const initUser = /* GraphQL */ `
  query InitUser(
    $email: String
    $sub: String
    $userId: String
    $userPoolId: String
  ) {
    initUser(email: $email, sub: $sub, userId: $userId, userPoolId: $userPoolId)
  }
`;
export const addUserCognitoGroups = /* GraphQL */ `
  query AddUserCognitoGroups(
    $userRoles: String
    $userPoolId: String
    $email: String
  ) {
    addUserCognitoGroups(
      userRoles: $userRoles
      userPoolId: $userPoolId
      email: $email
    )
  }
`;
export const updateUserCognitoGroups = /* GraphQL */ `
  query UpdateUserCognitoGroups(
    $userRoles: String
    $userPoolId: String
    $email: String
    $cognitoId: String
  ) {
    updateUserCognitoGroups(
      userRoles: $userRoles
      userPoolId: $userPoolId
      email: $email
      cognitoId: $cognitoId
    )
  }
`;
export const listUserCognitoGroups = /* GraphQL */ `
  query ListUserCognitoGroups($userPoolId: String, $cognitoId: String) {
    listUserCognitoGroups(userPoolId: $userPoolId, cognitoId: $cognitoId)
  }
`;
export const listCognitoGroups = /* GraphQL */ `
  query ListCognitoGroups($userPoolId: String) {
    listCognitoGroups(userPoolId: $userPoolId)
  }
`;
export const createCognitoGroup = /* GraphQL */ `
  query CreateCognitoGroup($userPoolId: String, $groupName: String) {
    createCognitoGroup(userPoolId: $userPoolId, groupName: $groupName)
  }
`;
export const removeUserCognitoGroups = /* GraphQL */ `
  query RemoveUserCognitoGroups(
    $userRoles: String
    $userPoolId: String
    $email: String
  ) {
    removeUserCognitoGroups(
      userRoles: $userRoles
      userPoolId: $userPoolId
      email: $email
    )
  }
`;
export const serviceRegisterEdit = /* GraphQL */ `
  query ServiceRegisterEdit($service: String, $token: String) {
    serviceRegisterEdit(service: $service, token: $token)
  }
`;
export const serviceRegisterAdd = /* GraphQL */ `
  query ServiceRegisterAdd($service: String, $token: String) {
    serviceRegisterAdd(service: $service, token: $token)
  }
`;
export const serviceRegisterDelete = /* GraphQL */ `
  query ServiceRegisterDelete($service: String, $token: String) {
    serviceRegisterDelete(service: $service, token: $token)
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      message
      userId
      type
      createdAt
      requestId
      ProgressPercent
      updatedAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $id: ID
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMessages(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        message
        userId
        type
        createdAt
        requestId
        ProgressPercent
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQueryGPT = /* GraphQL */ `
  query GetQueryGPT($id: ID!) {
    getQueryGPT(id: $id) {
      id
      user
      query
      response
      sources
      feedbackBool
      feedbackText
      feedbackBy
      createdAt
      responseTime
      language
      origin
      updatedAt
      __typename
    }
  }
`;
export const listQueryGPTS = /* GraphQL */ `
  query ListQueryGPTS(
    $id: ID
    $filter: ModelQueryGPTFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listQueryGPTS(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        user
        query
        response
        sources
        feedbackBool
        feedbackText
        feedbackBy
        createdAt
        responseTime
        language
        origin
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoId
      email
      name
      coreId
      displayName
      firstName
      surname
      jobTitle
      officeLocation
      roles
      photo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        cognitoId
        email
        name
        coreId
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDailyActivity = /* GraphQL */ `
  query GetDailyActivity($id: ID!) {
    getDailyActivity(id: $id) {
      id
      route
      users
      payload
      type
      message
      hasPayload
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDailyActivities = /* GraphQL */ `
  query ListDailyActivities(
    $id: ID
    $filter: ModelDailyActivityFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDailyActivities(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        route
        users
        payload
        type
        message
        hasPayload
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getActivityPayload = /* GraphQL */ `
  query GetActivityPayload($id: String!) {
    getActivityPayload(id: $id) {
      id
      payload
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listActivityPayloads = /* GraphQL */ `
  query ListActivityPayloads(
    $id: String
    $filter: ModelActivityPayloadFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listActivityPayloads(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        payload
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryGPTSByOriginAndCreatedAt = /* GraphQL */ `
  query QueryGPTSByOriginAndCreatedAt(
    $origin: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQueryGPTFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryGPTSByOriginAndCreatedAt(
      origin: $origin
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        query
        response
        sources
        feedbackBool
        feedbackText
        feedbackBy
        createdAt
        responseTime
        language
        origin
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoId
        email
        name
        coreId
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
